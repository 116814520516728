import { Routes } from "@blitzjs/next"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { Value } from "@stringtale/react"
import Link from "next/link"
import { Suspense, useState } from "react"
import { DARK_BLUE_COLOR, LIGHT_ORANGE_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX11, PX14, PX20 } from "theme/sizes"
import Button from "ui/Button"
import Center from "ui/Center"
import Group from "ui/Group"
import Loader from "ui/Loader"
import RoundedButtonBase from "ui/RoundedButton"
import Text from "ui/Text"
import getCategories from "../queries/getCategories"
import * as Menu from "./Menu"
import * as ScrollArea from "ui/ScrollArea"

const MAX_WIDTH = "1126px"
const MARGIN = "300px"
const LEFT_WIDTH = "230px"
const MAX_HEIGHT = "600px"

const Root = styled(Menu.Root)`
  width: calc(100vw - ${MARGIN});
  height: 70vh;
  max-height: ${MAX_HEIGHT};
  max-width: ${MAX_WIDTH};
`

const Content = styled(Menu.Content)`
  padding: 0;
  flex: 1 0 ${LEFT_WIDTH};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: inherit;
  overflow: hidden;
  height: 100%;
  border-radius: 16px;
`

const Chevron = styled.div`
  font-weight: bold;
  color: ${ORANGE_COLOR};
`

const CategoryMenuTitle = styled.a`
  color: ${DARK_BLUE_COLOR};
  font-weight: 600;
  font-size: ${PX14};
  padding: 8px 16px 8px 25px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    div {
      ${Text} {
        text-decoration: underline;
      }

      ${Chevron} {
        text-decoration: none;
      }
    }
  }
`

const CategoryMenuItem = styled(Button)`
  color: ${DARK_BLUE_COLOR};
  width: 100%;
  padding: 5px 16px 5px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  box-sizing: border-box;
  &:hover {
    div {
      ${Text} {
        text-decoration: underline;
      }

      ${Chevron} {
        text-decoration: none;
      }
    }
  }
  [data-state="open"] > & {
    background-color: ${LIGHT_ORANGE_COLOR};
  }
`.withComponent("a")

const CategoryRoot = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(${MAX_HEIGHT});
  @media (max-height: 860px) {
    max-height: calc(72vh - 20px);
  }
  border-radius: 0 16px 16px 0;
  box-sizing: border-box;
  padding: 16px 0;
`

const CategoryItems = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  /* @media (min-width: 1000px) {
    column-count: 2;
  }
  @media (min-width: 1200px) {
    column-count: 3;
  } */
`

const CategoryItem = styled.div`
  /* flex: 1 0 250px; */
  margin-bottom: 20px;

  /* @media (min-width: 1000px) {
    // https://stackoverflow.com/questions/7785374/how-to-prevent-column-break-within-an-element
    display: inline-block;
    page-break-inside: avoid;
    break-inside: avoid-column;
  } */
`

const RoundedButton = styled(RoundedButtonBase)`
  align-self: center;
`

function CategoriesContent({ onClose }: { onClose?: () => void }) {
  const [categories] = useQuery(getCategories, undefined)
  return (
    <ScrollArea.Root type="scroll">
      <ScrollArea.Viewport>
        <CategoryRoot>
          <Group>
            <Menu.Title>
              <Text size={PX20}>
                <Value name="apps.web.src.header.components.lessonmenu.categorie_n">
                  Categorieën
                </Value>
              </Text>
            </Menu.Title>
            <RoundedButton as={Link} {...Routes.CategoriesPage()} onClick={onClose} size="small">
              <Text size={PX11}>
                <Value name="apps.web.src.header.components.lessonmenu.bekijk_alles">
                  Bekijk alles
                </Value>
              </Text>
            </RoundedButton>
          </Group>
          <CategoryItems>
            {categories.map((category) => (
              <CategoryItem>
                <CategoryMenuTitle
                  as={Link}
                  {...Routes.CategoryPage({ category: category.slug })}
                  onClick={onClose}
                >
                  <Group gap={"4px"}>
                    <Text>{category.displayTitle}</Text>
                    <Chevron>&gt;</Chevron>
                  </Group>
                </CategoryMenuTitle>

                {category.themes.map((theme) => (
                  <DropdownMenu.Item>
                    <CategoryMenuItem
                      as={Link}
                      {...(theme.isStudio
                        ? Routes.StudioPage()
                        : Routes.ThemeIndexPage({
                            category: category.slug,
                            theme: theme.slug,
                          }))}
                      onClick={onClose}
                    >
                      <Group gap={"4px"}>
                        <Text>{theme.displayTitle}</Text>
                        <Chevron>&gt;</Chevron>
                      </Group>
                    </CategoryMenuItem>
                  </DropdownMenu.Item>
                ))}
              </CategoryItem>
            ))}
          </CategoryItems>
        </CategoryRoot>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar>
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  )
}

export default function LessonMenu({ trigger }: { trigger: React.ReactNode }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <DropdownMenu.Root modal open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Content asChild align="start" alignOffset={-70}>
        <Root>
          <DropdownMenu.Arrow
            style={{ fill: "white" }}
            width={30}
            height={15}
          />
          <Content>
            <Suspense
              fallback={
                <Center>
                  <Loader />
                </Center>
              }
            >
              <CategoriesContent onClose={() => setIsMenuOpen(false)} />
            </Suspense>
          </Content>
        </Root>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
