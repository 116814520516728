import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import * as Dialog from "@radix-ui/react-dialog"
// import * as Form from "@radix-ui/react-form"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react/dist"
import Field from "app/core/components/Field"
import useToast from "app/toasts/hooks/useToast"
import { useState } from "react"
import { LIST_COLORS } from "theme/colors"
import {
  Title,
  Buttons,
  CancelButton,
  Close,
  Content,
  Overlay,
  Root,
  SubmitButton,
  ScrollArea,
} from "ui/Modal"
import Stack from "ui/Stack"
import createFormFactory from "utils/form/createForm"
import addUserPlaylist, {
  AddUserPlaylistResult,
} from "../mutations/addUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import { AddUserListInput } from "../validations"
import Color, { ColorGroup } from "./Color"
import FieldArea from "app/core/components/FieldArea"
import PlaylistAccessLevels from "./PlaylistAccessLevels"
import { z } from "zod"
import SelectGroupModal from "app/groups/components/SelectGroupModal"
import { GroupSelectItem } from "app/groups/components/GroupSelectItem"
import Hr from "ui/Hr"
import Text from "ui/Text"
import { PX14 } from "theme/sizes"
import { Playlist } from "db"

export type SelectPlaylistAccessLevels = z.infer<
  typeof AddUserListInput
>["accessLevel"]

const form = createFormFactory({
  validationSchema: AddUserListInput,
})

const NewPlaylistModal = ({
  onClose,
}: {
  onClose: (playlist?: AddUserPlaylistResult["userLists"][number]) => void
}) => {
  const [addPlaylistMutation] = useMutation(addUserPlaylist)
  const [serverErrors, setServerErrors] = useState()
  const [color, setColor] = useState(LIST_COLORS[0])
  const addToast = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [accessLevel, setAccessLevel] =
    useState<SelectPlaylistAccessLevels>("CURRENT_GROUP")
  const [data, setData] = useState<z.infer<typeof AddUserListInput> | null>(
    null
  )

  const onSubmit = async (
    data: z.infer<typeof AddUserListInput>,
    selectedGroups?: number[]
  ) => {
    const playlist = await addPlaylistMutation({
      accessLevel:
        data.accessLevel === "PERSONAL" ? "PERSONAL" : "CURRENT_GROUP",
      displayTitle: data.displayTitle as string,
      description: data.description as string,
      color,
      selectedGroups,
    })
    addToast({
      content: (
        <Value name="apps.web.src.themes.components.newplaylistmodal.lijst_is_aangemaakt">
          {`Lijst is aangemaakt.`}
        </Value>
      ),
    })
    await invalidateQuery(getGroupedUserPlaylists)
    onClose(playlist.userLists[0])
  }

  if (data) {
    return (
      <SelectGroupModal
        onSubmit={async (selectedGroups) => {
          await onSubmit(data, selectedGroups)
        }}
        onClose={() => {
          setData(null)
          onClose()
          setAccessLevel("CURRENT_GROUP")
          setIsOpen(false)
        }}
        onRenderItem={(props) => <GroupSelectItem {...props} />}
      >
        <Stack gap="16px">
          <Title>
            <Value name="apps.web.src.playlists.components.newplaylistmodal.zichtbaar_voor_andere_groepen">
              Zichtbaar voor andere groepen
            </Value>
          </Title>
          <Hr />
        </Stack>
        <Text size={PX14}>
          <Value name="apps.web.src.playlists.components.newplaylistmodal.selecteer_n_of_meerdere_groepen_om_waarvoor_je_deze_lijst_wilt_opslaan_duo_s_uit_geselecteerde_groepen_krijgen_deze_lijst_automatisch_te_zien">
            Selecteer één of meerdere groepen om waarvoor je deze lijst wilt
            opslaan. Duo’s uit geselecteerde groepen krijgen deze lijst
            automatisch te zien.
          </Value>
        </Text>
      </SelectGroupModal>
    )
  }
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <Root>
          <ScrollArea>
            <form.Form
              noValidate
              defaultValues={{
                color,
                accessLevel,
              }}
              onSubmit={async (data) => {
                if (accessLevel === "SELECTED_GROUP") {
                  setData(data)
                  return
                }
                try {
                  onSubmit(data)
                } catch (e) {
                  setServerErrors(e)
                }
              }}
            >
              <Content>
                <Stack gap="24px">
                  <Dialog.Title>
                    <Value name="apps.web.src.playlists.components.newplaylistmodal.nieuwe_afspeellijst">
                      Nieuwe afspeellijst
                    </Value>
                  </Dialog.Title>
                  <Field
                    name="displayTitle"
                    label={
                      <Value name="apps.web.src.playlists.components.newplaylistmodal.naam">
                        Naam
                      </Value>
                    }
                    form={form}
                  />
                  <FieldArea
                    name="description"
                    label={
                      <Value name="apps.web.src.playlists.components.newplaylistmodal.omschrijving">
                        Omschrijving
                      </Value>
                    }
                    form={form}
                  />
                  <ColorGroup onValueChange={setColor} value={color}>
                    {LIST_COLORS.map((color) => (
                      <Color value={color} />
                    ))}
                  </ColorGroup>
                  <PlaylistAccessLevels
                    form={form}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    onValueChange={(v) =>
                      setAccessLevel(v as SelectPlaylistAccessLevels)
                    }
                    accessLevel={accessLevel}
                  />
                  <Buttons>
                    <Dialog.Close asChild>
                      <CancelButton type="button">
                        <Value name="apps.web.src.playlists.components.newplaylistmodal.annuleren">
                          Annuleren
                        </Value>
                      </CancelButton>
                    </Dialog.Close>
                    <SubmitButton type="submit">
                      {accessLevel === "SELECTED_GROUP" ? (
                        <Value name="apps.web.src.playlists.components.newplaylistmodal.volgende">
                          Volgende
                        </Value>
                      ) : (
                        <Value name="apps.web.src.playlists.components.newplaylistmodal.opslaan">
                          Opslaan
                        </Value>
                      )}
                    </SubmitButton>
                  </Buttons>
                </Stack>
              </Content>
            </form.Form>
          </ScrollArea>
          <Dialog.Close asChild>
            <Close>
              <FaTimes />
            </Close>
          </Dialog.Close>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default NewPlaylistModal
