import { Routes } from "@blitzjs/next"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { TbChevronRight } from "@react-icons/all-files/tb/TbChevronRight"
import { Value } from "@stringtale/react"
import { GetBulletinBoardItemsResult } from "app/bulletinboard/queries/getBulletinBoardItems"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import getLearningLineLinkProps from "app/learning-lines/utils/getLearningLineLinkProps"
import getLessonTypeTitle from "app/lessons/utils/getLessonTypeTitle"
import { BulletinBoardItemType } from "db"
import { motion } from "framer-motion"
import { ComponentProps, ReactNode, forwardRef, useMemo, useState } from "react"
import { PX12, PX14, PX16, PX20 } from "theme/sizes"
import Button from "ui/Button"
import Grow from "ui/Grow"
import Link from "ui/Link"
import Stack from "ui/Stack"
import YoutubeModal from "../../home/components/YoutubeModal"
import BulletinBoardLLCard from "./BulletinBoardLLCard"
import Badge from "ui/Badge"
import {
  BURGUNDY_COLOR,
  DARK_BLUE_COLOR,
  GREEN_COLOR,
  LIGHT_BLUE_COLOR,
  ORANGE_COLOR,
  PURPLE_COLOR,
} from "theme/colors"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import getContentSubjectTitle from "app/content-subjects/getContentSubjectTitle"

type BulletinBoardItem = GetBulletinBoardItemsResult[number]

export const Root = styled.div<{ isDragging?: boolean; color?: string }>`
  aspect-ratio: 1/1;
  background-color: ${(p) => (p.color ? p.color : DARK_BLUE_COLOR)};
  border-radius: 8px;
  position: relative;
  overflow: clip;
  box-shadow: 0px 3px 6px #00000029;
  ${({ isDragging }) =>
    isDragging
      ? css`
          z-index: 1;
          box-shadow: 0px 3px 10px #000000e6;
          border-radius: 4px;
        `
      : ""}
`

export const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  flex: 0 0 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const RoundedButtonBase = styled(Button)`
  border: none;
  text-decoration: none;
  border-radius: 100px;
  color: #ffffff;
  background-color: #ffffff4d;
  font-size: ${PX14};
  display: flex;
  text-align: left;
  align-items: center;
  gap: 27px;
  padding: 5px 14px;
  /* text-wrap: nowrap; */
  /* height: 42px; */
`

const FillImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const GrowRoundedButton = styled(RoundedButtonBase)`
  flex-grow: 1;
`

const RoundedButton = styled(RoundedButtonBase)`
  padding: 14px 20px;
`

const DarkRoundedButton = styled(RoundedButton)`
  background-color: #03004e;
`

const Bottom = styled(Stack)`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  gap: 6px;
  box-sizing: border-box;
  width: auto;
`

const Title = styled.div`
  display: -webkit-box;
  font-size: ${PX16};
  letter-spacing: 0.32px;
  color: #ffffff;
  text-shadow: 0px 0px 0px #0000009a;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 90px;
  line-height: 1.375;
  font-weight: 600;
`

const AvatarWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  gap: 6px;
  box-sizing: border-box;
  width: auto;
`

const HoverZoom = styled.div`
  scale: 1;
  height: 100%;
  width: 100%;
`.withComponent(motion.div)

const EmptyContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`

const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2871631a;
  border-radius: 4px;
`

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  height: 180px;
  opacity: 0.7;
`

type TypeKeyMap = BulletinBoardItemType | "LL_LESSON"

const LABEL_BY_TYPE: Record<TypeKeyMap, ReactNode> = {
  LESSON: (
    <Value name="apps.web.src.home.components.bulletinboardcard.les">Les</Value>
  ),
  LL_LESSON: (
    <Value name="apps.web.src.home.components.bulletinboardcard.leerlijn_les">
      Leerlijn Les
    </Value>
  ),
  LL_PAGE: (
    <Value name="apps.web.src.home.components.bulletinboardcard.leerlijn_les">
      Leerlijn
    </Value>
  ),
  THEME: (
    <Value name="apps.web.src.home.components.bulletinboardcard.thema">
      Thema
    </Value>
  ),
  THEME_GROUP: (
    <Value name="apps.web.src.home.components.bulletinboardcard.thema_groep">
      Thema groep
    </Value>
  ),
  GROUP_LIST: (
    <Value name="apps.web.src.home.components.bulletinboardcard.groep">
      Groepslijst
    </Value>
  ),
  SCHOOL_LIST: (
    <Value name="apps.web.src.home.components.bulletinboardcard.school">
      Schoollijst
    </Value>
  ),
  KIDS_LIST: "Kidslijst",
  KIDS_PREVIEW: "",
  LL: "",
  SUPPORT: "",
  YOUTUBE: "Youtube",
  URL: "",
}

const LABEL_COLOR_BY_TYPE: Record<TypeKeyMap, string> = {
  LESSON: GREEN_COLOR,
  LL_LESSON: LIGHT_BLUE_COLOR,
  THEME: ORANGE_COLOR,
  THEME_GROUP: ORANGE_COLOR,
  GROUP_LIST: BURGUNDY_COLOR,
  SCHOOL_LIST: PURPLE_COLOR,
  KIDS_LIST: "",
  KIDS_PREVIEW: "",
  LL: "",
  LL_PAGE: GREEN_COLOR,
  SUPPORT: "",
  YOUTUBE: "#CD1D20",
  URL: "",
}

const getImageUrl = (item: BulletinBoardItem) => {
  if (item.type === "LESSON" && item.lesson?.listImage) {
    return item.lesson.listImage
  }
  if (item.type === "THEME" && item.theme?.listImage) {
    return item.theme.listImage
  }
  if (item.type === "THEME_GROUP" && item.themeGroup?.theme?.listImage) {
    return item.themeGroup.theme.listImage
  }
  if (item.type === "LL_PAGE" && item.learningLine?.cardImage) {
    return item.learningLine.cardImage
  }
  // if (item.type === "PLAYLIST" && item.playlist?.playlist?.lessons[0]?.listImage) {
  // return item.lesson.image?.url
}

const getIsPlaylist = (item: BulletinBoardItem) => {
  return (
    item.type === "GROUP_LIST" ||
    item.type === "KIDS_LIST" ||
    item.type === "SCHOOL_LIST"
  )
}

const CONST_DISPLAY_TITLES = {
  LL: (
    <Value name="apps.web.src.bulletinboard.components.bulletinboardcard.leerlijnen">
      Leerlijnen
    </Value>
  ),
  KIDS_PREVIEW: (
    <Value name="apps.web.src.bulletinboard.components.bulletinboardcard.kids_preview">
      Kids preview
    </Value>
  ),
  SUPPORT: (
    <Value name="apps.web.src.bulletinboard.components.bulletinboardcard.support">
      Support
    </Value>
  ),
}

export const getDisplayTitle = (item: BulletinBoardItem) => {
  if (item.type === "LESSON") {
    return item.lesson?.displayTitle
  }
  if (getIsPlaylist(item) && item.playlist) {
    return item.playlist?.playlist?.displayTitle
  }
  if (item.type === "THEME" && item.theme) {
    return item.theme.displayTitle
  }
  if (item.type === "THEME_GROUP" && item.themeGroup) {
    return item.themeGroup.displayTitle
  }
  if (item.type === "LL_PAGE") {
    return item.learningLine?.displayTitle
  }
  return CONST_DISPLAY_TITLES[item.type] || item.displayTitle || ""
}

export const getLinkProps = (item: BulletinBoardItem) => {
  if (item.type === "SUPPORT") {
    return {
      as: Link,
      href: "https://123zing.my.site.com/knowledgebase/s/",
      target: "_blank",
    }
  }
  if (item.type === "KIDS_PREVIEW") {
    return {
      as: Link,
      ...Routes.KidsPage(),
      target: "_blank",
    }
  }
  if (item.type === "THEME" && item.theme) {
    return {
      as: Link,
      ...Routes.ThemeIndexPage({
        theme: item.theme.slug,
        category: item.theme.category.slug,
      }),
    }
  }
  if (item.type === "THEME_GROUP" && item.themeGroup) {
    if (!item.themeGroup.theme) return {}
    return {
      as: Link,
      ...Routes.ThemePage({
        category: item.themeGroup.theme.category.slug,
        theme: item.themeGroup.theme.slug,
        group: item.themeGroup.slug,
      }),
    }
  }
  if (item.type === "LESSON" && item.lesson) {
    return {
      as: Link,
      ...Routes.LessonPage({
        lesson: item.lesson.slug,
      }),
    }
  }
  if (getIsPlaylist(item) && item.playlist) {
    return {
      as: Link,
      href: `?bibliotheek=${item.playlist?.id}`,
    }
  }
  if (item.type === "LL_PAGE" && item.learningLine) {
    const props = getLearningLineLinkProps(item.learningLine)
    if (!props) {
      return {}
    }
    return {
      as: Link,
      ...props,
    }
  }
}

type Props = {
  item: BulletinBoardItem
} & ComponentProps<typeof Root>

const BulletinBoardCard = forwardRef<HTMLDivElement, Props>(
  ({ item, children, ...props }, ref) => {
    const currentSchool = useCurrentOrganization()
    const currentUser = useCurrentUser()
    const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false)
    const [isHovering, setHovering] = useState(false)
    const file = getImageUrl(item)
    const type =
      item.type === "LESSON" && item.lesson?.isLearningLineLesson
        ? "LL_LESSON"
        : item.type
    const learningLineGradeText = useMemo(() => {
      const list = item.learningLine?.learningLineTheme.learningLine.grades.map(
        (item) => item.number
      )
      list?.sort()
      return list?.join("-")
    }, [item.learningLine?.learningLineTheme.learningLine.grades])

    if (type === "SUPPORT") {
      return (
        <Root {...props} ref={ref}>
          {children}
          <Content>
            <Grow />
            <DarkRoundedButton>
              <Grow>
                <Value name="apps.web.src.home.components.bulletinboardcard.ondersteuning">
                  Ondersteuning
                </Value>
              </Grow>
              <TbChevronRight size={20} />
            </DarkRoundedButton>
          </Content>
        </Root>
      )
    }

    if (type === "KIDS_PREVIEW") {
      return (
        <Root {...props} ref={ref}>
          {children}
          <Content>
            <Grow />
            <RoundedButton>
              <Grow>
                <Value name="apps.web.src.home.components.bulletinboardcard.kidspagina_preview">
                  Kidspagina preview
                </Value>
              </Grow>
              <TbChevronRight size={20} />
            </RoundedButton>
          </Content>
        </Root>
      )
    }
    if (type === "LL") {
      return (
        <BulletinBoardLLCard ref={ref} {...props}>
          {children}
        </BulletinBoardLLCard>
      )
    }

    return (
      <>
        {item.type === "YOUTUBE" && item.youtubeId && (
          <Dialog.Root
            open={isYoutubeModalOpen}
            onOpenChange={setIsYoutubeModalOpen}
          >
            <YoutubeModal videoId={item.youtubeId} />
          </Dialog.Root>
        )}
        <Root
          {...props}
          ref={ref}
          style={{ cursor: "pointer", ...props.style }}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={() => {
            if (item.type === "YOUTUBE") {
              setIsYoutubeModalOpen(true)
            }
          }}
          color={"transparant"}
        >
          {children}
          <HoverZoom
            animate={isHovering ? { scale: 1.1 } : { scale: 1 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
          >
            {item.type === "URL" || item.type === "YOUTUBE" ? (
              item.previewImageUrl ? (
                <FillImg src={item.previewImageUrl} />
              ) : null
            ) : getIsPlaylist(item) ? (
              <ImageGrid>
                {item.playlist?.playlist?.lessons.map((lesson) =>
                  lesson.lesson?.listImage ? (
                    <Image
                      key={lesson.id}
                      alt=""
                      file={lesson.lesson?.listImage}
                      width={150}
                      height={150}
                    />
                  ) : null
                )}
                {item.playlist?.playlist?.lessons &&
                  [...Array(4 - item.playlist.playlist.lessons.length)].map(
                    function () {
                      return (
                        <EmptyContainer>
                          <EmptyImage />
                        </EmptyContainer>
                      )
                    }
                  )}
              </ImageGrid>
            ) : file ? (
              <Image
                alt=""
                file={file}
                fill
                options={{ height: 300 }}
                sizes="(max-width: 767px) 50vw, (max-width: 1023px) 30vw, (max-width: 1279px) 20vw, 20vw"
                style={{ objectFit: "cover" }}
              />
            ) : null}
          </HoverZoom>
          {item.owner && currentUser?.id !== item.ownerId && (
            <AvatarWrapper>
              <Avatar
                user={item.owner}
                width={37}
                height={37}
                withBorder
                borderColor={"white"}
              />
            </AvatarWrapper>
          )}
          <GradientOverlay />
          <Bottom align="flex-start">
            <Title>{getDisplayTitle(item)}</Title>
            <Badge
              hasShadow
              backgroundColor={
                type === "LL_PAGE" && item.learningLine
                  ? getContentSubjectColor(
                      item.learningLine.learningLineTheme.learningLine
                        .contentSubject,
                      "medium"
                    )
                  : LABEL_COLOR_BY_TYPE[type]
              }
            >
              {type === "LESSON" && item.lesson ? (
                getLessonTypeTitle(item.lesson.lessonType)
              ) : type === "LL_PAGE" && item.learningLine ? (
                <Value
                  name="apps.web.src.bulletinboard.components.bulletinboardcard.leerlijn_label"
                  format={{
                    type: LABEL_BY_TYPE[type],
                    contentSubject: getContentSubjectTitle(
                      item.learningLine?.learningLineTheme.learningLine
                        .contentSubject
                    )?.toLowerCase(),
                    cardLabel: item.learningLine.hasAlternativeGradesLabelBehaviour ? '4S' : item.learningLine?.cardLabel,
                    grades: item.learningLine.hasAlternativeGradesLabelBehaviour ? item.learningLine.gradesLabelOverwrite : learningLineGradeText,
                  }}
                >
                  {`{type} {contentSubject} | {grades} | {cardLabel}`}
                </Value>
              ) : (
                LABEL_BY_TYPE[type]
              )}
            </Badge>
          </Bottom>
        </Root>
      </>
    )
  }
)

BulletinBoardCard.displayName = "BulletinBoardCard"

export default BulletinBoardCard
