import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { TbArrowRight } from "@react-icons/all-files/tb/TbArrowRight"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { Value } from "@stringtale/react"
import addBulletinBoardItem from "app/bulletinboard/mutations/addBulletinBoardItem"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"
import {
  Content,
  Root as RootBase,
} from "app/lessons/components/LessonListItem"
import parseStringTime from "app/rehearsal/utils/parseStringTime"
import { BulletinBoardItemType, File, ParticipationIcon } from "db"
import { useState } from "react"
import { DIMMED_BLUE_COLOR, PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import ContextMenu from "ui/ContextMenu"
import Grades from "ui/Grades"
import Group from "ui/Group"
import Link from "ui/Link"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getAllGrades from "web/src/grades/queries/getAllGrades"
import LessonDescriptionPreview from "web/src/lessons/components/LessonDescriptionPreview"
import LessonListItemTitle from "web/src/lessons/components/LessonListItemTitle"
import LessonPreview, {
  PreviewLink,
} from "web/src/lessons/components/LessonPreview"
import LessonSubTitle from "web/src/lessons/components/LessonSubTitle"
import { GetSharedWithMeResult } from "../queries/getSharedWithMe"
import AddToPlaylistModal from "./AddToPlaylistModal"

export type TParticipantsIcons = ParticipationIcon & {
  image: File | null
}

const Root = styled(RootBase)`
  flex: 1;
`

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

type Props = {
  lesson: NonNullable<GetSharedWithMeResult[number]["lesson"]>
  onDelete: () => void
  // owner: UserPlaylistTypeOwner
}

const ShareLessonItem = ({ lesson, onDelete, ...props }: Props) => {
  const [grades] = useQuery(getAllGrades, undefined)
  const [isAddToPlaylistOpen, setAddToPlaylistOpen] = useState(false)
  const [addBulletinBoardItemMutation] = useMutation(addBulletinBoardItem)
  if (!lesson) return <></>
  return (
    <Root {...props}>
      <Group gap="20px">
        {lesson.cardImage && (
          <PreviewLink
            {...Routes.LessonPage({
              lesson: lesson.slug,
            })}
          >
            <LessonPreview
              // size={{ b
              //   height:
              // }}
              previewId={`listitem-${lesson.id}`}
              isPlayVisible={
                !!lesson?.previewVideo ||
                !!lesson?.previewAudio ||
                !!lesson?.rehearse?.video ||
                !!lesson?.radioSong
              }
              image={lesson.cardImage}
              previewBegin={
                lesson?.previewBegin
                  ? parseStringTime(lesson.previewBegin)
                  : undefined
              }
              previewEnd={
                lesson?.previewEnd
                  ? parseStringTime(lesson.previewEnd)
                  : undefined
              }
              file={
                lesson?.previewVideo ||
                lesson?.previewAudio ||
                lesson?.rehearse?.video ||
                lesson?.radioSong
              }
            />
          </PreviewLink>
        )}
        <Content>
          <Stack gap="8px">
            <Stack>
              <Text size={PX12}>
                <Value name="apps.web.src.playlists.components.sharelessonitem.les">
                  Les
                </Value>
              </Text>
              {lesson.displayTitle && (
                <Link
                  {...Routes.LessonPage({
                    lesson: lesson.slug,
                  })}
                >
                  <LessonListItemTitle
                    title={lesson.displayTitle}
                    contentSubject={lesson.contentSubject}
                  />
                </Link>
              )}
            </Stack>
            <LessonSubTitle size={PX12} lesson={lesson} />
            <Grades
              allGrades={grades}
              activeGrades={lesson.grades}
              contentSubjectType={lesson.contentSubject}
            />
            <LessonDescriptionPreview description={lesson.description} />
          </Stack>
        </Content>
      </Group>
      <Group align="center">
        <ButtonGroup>
          <RoundedButton
            size="small"
            as={Link}
            {...Routes.LessonPage({
              lesson: lesson.slug,
            })}
            variant="filled"
          >
            <Value name="apps.web.src.playlists.components.sharelessonitem.bekijk_les">
              Bekijk les
            </Value>
          </RoundedButton>
          <ContextMenu
            trigger={
              <RoundedButton color="orange" size="small" variant="filled">
                <FaEllipsisH />
              </RoundedButton>
            }
            items={
              <>
                <ContextMenu.Item>
                  <Group align="center" gap="8px">
                    <TbArrowRight color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelessonitem.context.bekijk_les">
                      Bekijk les
                    </Value>
                  </Group>
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      lessonUuid: lesson.uuid,
                      type: BulletinBoardItemType.LESSON,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  <Group align="center" gap="8px">
                    <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelessonitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  </Group>
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={() => {
                    setAddToPlaylistOpen(true)
                  }}
                >
                  <Group align="center" gap="8px">
                    <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelessonitem.context.les_opslaan">
                      Les toevoegen
                    </Value>
                  </Group>
                </ContextMenu.Item>
                <ContextMenu.Separator />
                <ContextMenu.Item onClick={onDelete}>
                  <Group align="center" gap="8px">
                    <TbTrash color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelessonitem.context.verwijder_les">
                      Verwijder les
                    </Value>
                  </Group>
                </ContextMenu.Item>
              </>
            }
          />
        </ButtonGroup>
      </Group>
      <AddToPlaylistModal.UnControlled
        isOpen={isAddToPlaylistOpen}
        setOpen={setAddToPlaylistOpen}
        lessons={[lesson]}
      />
    </Root>
  )
}

export default ShareLessonItem
