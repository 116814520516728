import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { TbArrowRight } from "@react-icons/all-files/tb/TbArrowRight"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { Value } from "@stringtale/react"
import Image from "app/files/components/Image"
import FollowLearningLineToGroupModal from "app/learning-lines/components/FollowLearningLineToGroupModal"
import getLearningLineLinkProps, {
  LearningLinePageLinkProps,
} from "app/learning-lines/utils/getLearningLineLinkProps"
import {
  Content,
  ImageRoot,
  Root as RootBase,
  Title,
} from "app/lessons/components/LessonListItem"
import { BulletinBoardItemType, File, LearningLineContentPage } from "db"
import NextImage from "next/image"
import { useState } from "react"
import { DIMMED_BLUE_COLOR, GREEN_COLOR } from "theme/colors"
import { PX12 } from "theme/sizes"
import ButtonGroup from "ui/ButtonGroup"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import Link from "ui/Link"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"
import PlaylistMenuCircle from "./PlaylistMenuCircle"
import { UserPlaylistTypeOwner } from "./UserPlaylistType"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import addBulletinBoardItem from "app/bulletinboard/mutations/addBulletinBoardItem"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

type ILearningLine =
  | (LearningLinePageLinkProps &
      (LearningLineContentPage & {
        playlistImage: File | null
      }))
  | null

const Root = styled(RootBase)`
  flex-grow: 1;
`

const ImageWrapper = styled(ImageRoot)`
  background-color: ${GREEN_COLOR};
  img {
    object-fit: cover;
  }
`

export default function ShareLearningLineItem({
  learningLine,
  owner,
  onDelete,
}: {
  learningLine: ILearningLine
  owner: UserPlaylistTypeOwner
  onDelete: () => void
}) {
  const [isFollowModalVisible, setFollowModalVisibe] = useState(false)
  const [addBulletinBoardItemMutation] = useMutation(addBulletinBoardItem)
  if (!learningLine) return null
  return (
    <Root>
      <Group gap="20px">
        <ImageWrapper>
          {learningLine?.playlistImage ? (
            <Image
              file={learningLine.playlistImage}
              alt=""
              height={135}
              width={240}
              options={{ fit: "cover", height: 135 }}
            />
          ) : (
            <NextImage src="/images/logo.svg" alt="" width={240} height={135} />
          )}
        </ImageWrapper>
        <Content>
          <Stack>
            <Text size={PX12}>{getListTypeDisplayTitle("GLOBAL", "LL")}</Text>
            <Link {...getLearningLineLinkProps(learningLine)}>
              <Group justify="flex-start" gap="8px" align="center">
                <PlaylistMenuCircle
                  color={GREEN_COLOR}
                  accessLevel="GLOBAL"
                  type="LL"
                />
                <Title>
                  {learningLine.playlistLabel || learningLine.displayTitle}
                </Title>
              </Group>
            </Link>
          </Stack>
        </Content>
      </Group>
      <Stack gap="24px" justify="center" align="flex-start">
        <ButtonGroup>
          <RoundedButton
            as={Link}
            {...getLearningLineLinkProps(learningLine)}
            size="small"
            variant="filled"
          >
            <Value name="apps.web.src.playlists.components.sharelearninglineitem.bekijk_lijst">
              Bekijk leerlijn
            </Value>
          </RoundedButton>
          <ContextMenu
            trigger={
              <RoundedButton color="orange" size="small" variant="filled">
                <FaEllipsisH />
              </RoundedButton>
            }
            items={
              <>
                <ContextMenu.Item>
                  <Group align="center" gap="8px">
                    <TbArrowRight color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelearninglineitem.context.bekijk_leerlijn">
                      Bekijk leerlijn
                    </Value>
                  </Group>
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      learningLineId: learningLine.id,
                      type: BulletinBoardItemType.LL_PAGE,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  <Group align="center" gap="8px">
                    <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelearninglineitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  </Group>
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={() => {
                    setFollowModalVisibe(true)
                  }}
                >
                  <Group align="center" gap="8px">
                    <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelearninglineitem.context.leerlijn_opslaan">
                      Leerlijn toevoegen
                    </Value>
                  </Group>
                </ContextMenu.Item>
                <ContextMenu.Separator />
                <ContextMenu.Item onClick={onDelete}>
                  <Group align="center" gap="8px">
                    <TbTrash color={DIMMED_BLUE_COLOR} size="18px" />
                    <Value name="apps.web.src.playlists.components.sharelearninglineitem.context.verwijder_leerlijn">
                      Verwijder leerlijn
                    </Value>
                  </Group>
                </ContextMenu.Item>
              </>
            }
          />
        </ButtonGroup>
      </Stack>
      <Dialog.Root
        open={isFollowModalVisible}
        onOpenChange={setFollowModalVisibe}
      >
        {isFollowModalVisible && (
          <FollowLearningLineToGroupModal
            learningLineId={learningLine.id}
            onClose={() => setFollowModalVisibe(false)}
          />
        )}
      </Dialog.Root>
    </Root>
  )
}
