import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH"
import { TbArrowRight } from "@react-icons/all-files/tb/TbArrowRight"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { Value } from "@stringtale/react"
import Image from "app/files/components/Image"
import {
  Content,
  ImageRoot,
  Root as RootBase,
  Title,
} from "app/lessons/components/LessonListItem"
import FollowThemeToGroupsModal from "app/themes/components/FollowThemeToGroupsModal"
import { BulletinBoardItemType, File, ThemeGroup } from "db"
import NextImage from "next/image"
import { Suspense, useState } from "react"
import { DIMMED_BLUE_COLOR, GREEN_COLOR } from "theme/colors"
import { PX12 } from "theme/sizes"
import ButtonGroup from "ui/ButtonGroup"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import Link from "ui/Link"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"
import PlaylistMenuCircle from "./PlaylistMenuCircle"
import { UserPlaylistTypeOwner } from "./UserPlaylistType"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import addBulletinBoardItem from "app/bulletinboard/mutations/addBulletinBoardItem"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

type IThemeGroup =
  | (ThemeGroup & {
      globalPlaylist: {
        playlist: {
          image: File | null
          displayTitle: string | null
          color: string | null
        }
      } | null
      theme: {
        slug: string
        category: {
          slug: string
        }
      } | null
    })
  | null

const Root = styled(RootBase)`
  flex-grow: 1;
`

const ImageWrapper = styled(ImageRoot)`
  background-color: ${GREEN_COLOR};
  img {
    object-fit: cover;
  }
`

export default function ShareThemeItem({
  themeGroup,
  owner,
  onDelete,
}: {
  themeGroup: IThemeGroup
  owner: UserPlaylistTypeOwner
  onDelete: () => void
}) {
  const [isFollowModalVisibe, setFollowModalVisibe] = useState(false)
  const [addBulletinBoardItemMutation] = useMutation(addBulletinBoardItem)
  if (!themeGroup?.theme) return null
  const link = Routes.ThemePage({
    theme: themeGroup.theme.slug,
    category: themeGroup.theme.category.slug,
    group: themeGroup.slug,
  })
  return (
    <Root>
      <Group gap="20px">
        <ImageWrapper>
          {themeGroup?.globalPlaylist?.playlist?.image ? (
            <Image
              file={themeGroup.globalPlaylist.playlist.image}
              alt=""
              height={135}
              width={240}
              options={{ fit: "cover", height: 135 }}
            />
          ) : (
            <NextImage src="/images/logo.svg" alt="" width={240} height={135} />
          )}
        </ImageWrapper>
        <Content>
          <Stack>
            <Text size={PX12}>
              {getListTypeDisplayTitle("GLOBAL", "THEME")}
            </Text>
            <Link {...link}>
              <Group justify="flex-start" gap="8px" align="center">
                <PlaylistMenuCircle
                  color={
                    themeGroup.globalPlaylist?.playlist?.color || GREEN_COLOR
                  }
                  accessLevel="GLOBAL"
                  type="THEME"
                />
                <Title>
                  {themeGroup.globalPlaylist?.playlist?.displayTitle}
                </Title>
              </Group>
            </Link>
          </Stack>
        </Content>
      </Group>
      <Stack gap="24px" justify="center" align="flex-start">
        {themeGroup.theme ? (
          <ButtonGroup>
            <RoundedButton as={Link} {...link} size="small" variant="filled">
              <Value name="apps.web.src.playlists.components.sharethemeitem.bekijk_lijst">
                Bekijk thema
              </Value>
            </RoundedButton>
            <ContextMenu
              trigger={
                <RoundedButton color="orange" size="small" variant="filled">
                  <FaEllipsisH />
                </RoundedButton>
              }
              items={
                <>
                  <ContextMenu.Item asChild>
                    <Link
                      {...Routes.ThemePage({
                        theme: themeGroup.theme.slug,
                        category: themeGroup.theme.category.slug,
                        group: themeGroup.slug,
                      })}
                    >
                      <Group align="center" gap="8px">
                        <TbArrowRight color={DIMMED_BLUE_COLOR} size="18px" />
                        <Value name="apps.web.src.playlists.components.sharethemeitem.context.bekijk_thema">
                          Bekijk thema
                        </Value>
                      </Group>
                    </Link>
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={async () => {
                      await addBulletinBoardItemMutation({
                        type: BulletinBoardItemType.THEME_GROUP,
                        themeGroupId: themeGroup.id,
                      })
                      await invalidateQuery(getBulletinBoardItems)
                    }}
                  >
                    <Group align="center" gap="8px">
                      <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                      <Value name="apps.web.src.playlists.components.sharethemeitem.zet_op_prikbord">
                        Zet op prikbord
                      </Value>
                    </Group>
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={() => {
                      setFollowModalVisibe(true)
                    }}
                  >
                    <Group align="center" gap="8px">
                      <TbPlus color={DIMMED_BLUE_COLOR} size="18px" />
                      <Value name="apps.web.src.playlists.components.sharethemeitem.context.thema_opslaan">
                        Thema toevoegen
                      </Value>
                    </Group>
                  </ContextMenu.Item>
                  <ContextMenu.Separator />
                  <ContextMenu.Item onClick={onDelete}>
                    <Group align="center" gap="8px">
                      <TbTrash color={DIMMED_BLUE_COLOR} size="18px" />
                      <Value name="apps.web.src.playlists.components.sharethemeitem.context.verwijder_les">
                        Verwijder les
                      </Value>
                    </Group>
                  </ContextMenu.Item>
                </>
              }
            />
          </ButtonGroup>
        ) : null}
      </Stack>
      <Dialog.Root
        open={isFollowModalVisibe}
        onOpenChange={setFollowModalVisibe}
      >
        {isFollowModalVisibe && (
          <Suspense>
            <FollowThemeToGroupsModal
              themeGroupId={themeGroup.id}
              onClose={() => setFollowModalVisibe(false)}
            />
          </Suspense>
        )}
      </Dialog.Root>
    </Root>
  )
}
